<template>
  <div>
    <div class="cotainer-standar">
      <div class="dk-container dk-potensi mt--res-150    ">
        <span
          class="d-block text-center font-30 font-res-25 text-white font-weight-6 mb-40  "
          >POTENSI KAMPUNG PROVINSI PAPUA BARAT</span
        >
        <div
          class="container-standar mt-10 mb-10 text-center pad-30 card shadow"
        >
          <div class="row">
            <div class="col-lg-3">
              <v-select
                placeholder="Pilih Kabupaten"
                :options="optionsKabupaten"
                v-model="filterWilayah.kabupaten"
                label="name"
                @input="setSelectedKabupaten"
              ></v-select>
            </div>
            <div class="col-lg-3 col-xs-12">
              <v-select
                placeholder="Pilih Distrik"
                :options="optionsDistrik"
                v-model="filterWilayah.distrik"
                label="name"
                @input="setSelectedDistrict"
              ></v-select>
            </div>
            <div class="col-lg-3 col-xs-12">
              <v-select
                placeholder="Pilih Desa/Kampung"
                :options="optionsKampung"
                v-model="filterWilayah.kampung"
                label="name"
              ></v-select>
            </div>
            <div class="col-lg-3 col-xs-12">
              <b-button
                @click="send(filterWilayah)"
                class="
                  bg-primary
                  text-white
                  border-radius-5
                  pad-5
                  cursor-pointer
                  font-weight-6
                  d-inline-block
                  width-100
                "
                >Lihat Potensi Kampung</b-button
              >
            </div>
          </div>
        </div>
        <div class="container-standar mb-10  mt-10 pad-20 card shadow">
          <h4
            v-if="onFilter === false"
            class="text-primary font-20 mb-20 font-weight-7 bor-bot-1 pb-4"
          >
            Kampung Populer
          </h4>
          <h4
            v-else
            class="text-primary font-20 mb-20 font-weight-7 bor-bot-1 pb-4"
          >
            Result 9 Kampung/Desa
          </h4>
          <div class="text-center" v-if="loading">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
          <div class="row" v-else>
            <div
              class="col-lg-4 mb-30 cursor-pointer"
              v-for="i in 9"
              :key="i"
            >
              <router-link
                :to="{
                  name: 'Detail Potensi Kampung',
                  params: { id: i },
                }"
              >
                <div class="list-kampung shadow card">
                  <div class="img-container">
                    <b-img src="https://pbs.twimg.com/media/DHlpbuPUwAANT69.jpg" alt fluid>
                      <b-btn
                        size="sm"
                        variant="primary"
                        sm
                        class="rounded-pill"
                        rounded-pill
                        >Kabupaten</b-btn
                      >
                    </b-img>
                  </div>
                  <div class="content p-3 pb-n4">
                    <!-- kampung: {{kampung}}
                    <span
                      class="
                        title
                        text-primary
                        d-inline-block
                        font-18
                        mb-10
                        font-weight-7
                      "
                      >Lorem Ipsum is simply dummy text of the printing and
                      type....</span
                    >
                    <span class="text-dark d-inline-block width-100 font-16"
                      >Daerah Wisata Pantai</span
                    > -->
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kampung/Desa :
                      <span class="font-weight-7 text-primary"
                        >Kampung Lorem</span
                      >
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Distrik :
                      <span class="font-weight-7 text-primary">
                        Distrik Ipsum
                      </span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kabupaten :
                      <span class="font-weight-7 text-primary">
                        Kabupeten Lorem
                      </span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kepala Kampung/Desa :
                      <span class="font-weight-7 text-primary">
                        Kepala Kampung
                      </span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Perkiraan jumlah keluarga :
                      <span class="font-weight-7 text-primary">5</span>
                    </span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <div
            class="
              flex
              width-100
              justify-center
              text-center
              mt-50
              pb-res-tab-small-40
            "
          >
            <b-pagination
              color="primary"
              v-model="page"
              :total-rows="total"
              :per-page="6"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const potensiKampung = createNamespacedHelpers("potensiKampung");

export default {
  data() {
    return {
      finder: null,
      filterWilayah: {
        kabupaten: null,
        distrik: null,
        kampung: null,
      },
      optionsKabupaten: [],
      optionsDistrik: [],
      optionsKampung: [],
      page: 1,
      filter: true,
      onFilter: false,
    };
  },
  computed: {
    ...potensiKampung.mapState(["potensiKampungs", "loading", "total"]),
    ...potensiKampung.mapGetters(["potensiKampungList"]),
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    // this.getListKabupaten();
  },
  created() {
    // this.getPotensiKampungList(this.page);
  },
  watch: {
    page() {
      if (this.onFilter === false) {
        this.getPotensiKampungList(this.page);
      } else {
        let filter = {
          city_id: this.filterWilayah.kabupaten
            ? this.filterWilayah.kabupaten.id
            : "",
          district_id: this.filterWilayah.distrik
            ? this.filterWilayah.distrik.id
            : "",
          village_id: this.filterWilayah.kampung
            ? this.filterWilayah.kampung.id
            : "",
        };
        this.filterPotensiKampungList({ form: filter, page: this.page });
      }
    },
  },
  methods: {
    ...potensiKampung.mapActions([
      "getPotensiKampungList",
      "filterPotensiKampungList",
    ]),
    setSelectedKabupaten(kabupaten) {
      this.getListDistrik(kabupaten.id);
      this.filterWilayah.distrik = "";
      this.filterWilayah.kampung = "";
    },
    setSelectedDistrict(district) {
      this.getListKampung(district.id);
      this.filterWilayah.kampung = "";
    },
    getListKabupaten() {
      this.$axios.get("/cities").then((response) => {
        this.optionsKabupaten = response.data;
      });
    },
    getListDistrik(kabupaten) {
      this.$axios
        .get("/cities/" + kabupaten + "/districts")
        .then((response) => {
          this.optionsDistrik = response.data;
        });
    },
    getListKampung(district) {
      this.$axios
        .get("/districts/" + district + "/kampung")
        .then((response) => {
          this.optionsKampung = response.data;
        });
    },
    send(form) {
      let filter = {
        city_id: form.kabupaten ? form.kabupaten.id : "",
        district_id: form.distrik ? form.distrik.id : "",
        village_id: form.kampung ? form.kampung.id : "",
      };
      this.page = 1;
      this.filterPotensiKampungList({ form: filter, page: this.page });
      this.onFilter = true;
    },
  },
};
</script>
